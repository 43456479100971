<template>
  <a href="https://preventivatore.bticino.it/" target="_blank" title="Preventivatore Bticino.it" class="button preventivatore" @click="track();">Crea la tua casa smart!</a>
</template>

<script>
export default {
  methods:{
    
  track(){
    window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'click_button',
        'risposta': 'Crea la tua casa smart',
      }); 
    }
  }
}
</script>