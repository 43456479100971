<template>
  <ViewQuestion v-if="Questions.ShowAnswer && !this.$store.state.ShowFlowVideoQuestion" 
    :key="Questions.id" 
    :defaultvideo="Questions.Defaultvideo" 
    :question="Questions.Question" 
    :answers="Questions.Answers"
    :time="Questions.Time" 
    :id="Questions.id">
  </ViewQuestion>
  <ViewVideo v-else :VideoSrc="this.VideoSrc" :QuestionSmart="Questions.QuestionSmart" :id="Questions.id" :SmartVideo="Questions.SmartVideo" :SmartImage="Questions.SmartImage" :SmartIdprofile="Questions.SmartIdprofile" :SmartText2="Questions.SmartText2"/>
</template>

<script>
import question from '../assets/json/data.json';
import ViewQuestion from './ViewQuestion.vue';
import ViewVideo from './ViewVideo.vue';


export default {
  components: {
    ViewQuestion,
    ViewVideo
  },

  data(){
    return {
      Questions: question.filter((question) => question.ShowAnswer == true)[0],
      ShowVideo : false,
      VideoSrc : null,
    }
  },
  mounted: function () {
    this.$root.$on('ShowVideo', (videoSrc) => {
      this.$store.state.ShowFlowVideoQuestion = true;
      this.VideoSrc = videoSrc;
    })
  },
}
</script>