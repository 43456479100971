<template>
  <div v-if="$store.state.NumberActiveAnswerUser >= MinUserAnswer">
      <div class="ShowProfile button" @click="ShowProfile()">Guarda il tuo profilo</div>
  </div>
</template>

<script>

import questions from '../../assets/json/data.json';

export default {

  data(){
    return {
      MinUserAnswer: 3,
      Questions : questions
    }
  },

  methods: {

    ShowProfile(){

      // Regole per calcolo profilo:
      // vince sempre il profilo con più voti
      // a parità di voti, vince il profilo TECNOLOGO (1)
      // a parità di voti, se non c’è il profilo TECNOLOGO nelle risposte, vince il primo profilo scelto (prima risposta data)

      let MatrixValueUserAnswer = [];
      this.$store.state.DataCollecting.map(item => { MatrixValueUserAnswer.push(item.value) })

      const set = new Set(MatrixValueUserAnswer);
      let Profiles = MatrixValueUserAnswer.filter(item => {
        if (set.has(item)) { set.delete(item); }
        else { return item; }
      });

      // Se non ho un valore che si ripete
      if(Profiles.length === 0) {
        if(MatrixValueUserAnswer.includes(1)) {
          Profiles = 1 ;
        }
        else {
          Profiles = MatrixValueUserAnswer[0];
        }
      }

      // Se ho piu valori che ri ripetono
      if(Profiles.length > 1) {
        if(Profiles.includes(1)){
          Profiles = 1 ;
        }
        else {
          Profiles = Profiles[0];
        }
      }

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'click_button',
        'risposta': 'Mostra profilo',
      }); 

      this.$root.$emit('ShowProfile', parseInt(Profiles));

    }
  }
}
</script>