import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const state = {
  NumberActiveAnswerUser: 0,
  DataCollecting: [],
  ShowFlowQuestion: false,
  ShowFlowVideoQuestion: false,
  ShowIDQuestion: null
}

export default new Vuex.Store({
  state
})