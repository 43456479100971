<template>
  <div class="crs-wrp-flex padding-70">
    <div class="wrp-question width-30 padding-20 radius" v-for="question in questions" :key="question.id" :class="{ active : question.UserReplied == true }">
      <div class="wrp-click" @click="showModal(question); track(question.id, question.description);">
        <div :class="[question.class, 'crs-wrp-flex', 'cs-q-top', 'yes-m-flex']">
          <div class="width-50 cq_icon"></div>
          <div class="width-50 cq_time fs_18">
            {{question.Time}}
          </div>
        </div>
        <p class="fs_16 titolo_domanda" v-html="question.description"></p>
      </div>
    </div>
  </div>
</template>

<script>
import question from '../assets/json/data.json';

export default {
  data(){
      return {
        questions: question,
      }
    },
  
  methods: {
    showModal(question) {
      question.ShowAnswer = !question.ShowAnswer;
      this.$store.state.ShowFlowQuestion = !this.$store.state.ShowFlowQuestion;
      this.$store.state.ShowIDQuestion = question.id;
    },

    track (id, title) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'Pageview',
      'pagePath': window.location.origin + '/domanda-' + id,
      'pageTitle': title.replace(/<\/?[^>]+(>|$)/gi, ""),
    });   
    },
  }
}
</script>
