<template>
  <div id="app">
    <div v-if="this.ShowProfile">
      <div @click="back();"><p class="button-back fs_20" >Back</p></div>
      <ViewProfile :ShowProfileID="this.ShowProfileID" />
    </div>
    <div v-else-if="this.$store.state.ShowFlowQuestion" class=""><FlowQuestions/></div>
    <div v-else class="crs-wrp-flex heigh100 no-m-flex">
      <img src="@/assets/image/logo-bticino-nero.svg" alt="Bticino" width="150" height="50" class="logo">
      <div class="crs-cnt-left width-50 relative"><ViewPhraseHP/></div>
      <div class="crs-quiz-wrp-questions height100 crs-cnt-right width-50" :class="{'ShowbuttonsMobile' : this.$store.state.NumberActiveAnswerUser >= 3}"><ViewBlocksQuestions/></div>
    </div>
    <a href="javascript:Didomi.preferences.show()" class="privacy">Consenso Privacy</a>
  </div>
</template>

<script>

import FlowQuestions from './components/FlowQuestions.vue';
import ViewProfile from './components/ViewProfile.vue';
import ViewBlocksQuestions from './components/ViewBlocksQuestions.vue';
import ViewPhraseHP from './components/ViewPhraseHP.vue';

export default {
  name: 'App',
  components: {
    FlowQuestions,
    ViewProfile,
    ViewBlocksQuestions,
    ViewPhraseHP
  },
  data(){
    return{
      ShowProfile: false,
      ShowProfileID: 0,
    }
  },
  mounted: function () {
    this.$root.$on('ShowProfile', (id) => { 
      this.ShowProfile = true;
      this.ShowProfileID = id;
    })
  },
  methods: {
    back() {
      this.ShowProfile = false;
      this.$store.state.ShowFlowQuestion = false;
      this.$store.state.ShowIDQuestion = null ;
      this.$store.state.ShowFlowVideoQuestion = false;
    }
  },
}
</script>