import Vue from 'vue'
import App from './App.vue'
import store from './store'
//import VueGtag from "vue-gtag";

require('@/assets/scss/main.scss');

// Vue.use(VueGtag, {  
//   config: { id: "UA-92381360-4" }
// });
Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  store,
}).$mount('#app')
