<template>
  
  <div v-if="this.VideoStart" :class="[Ismobile ? 'IsMactive' : 'Nomactive']">
    <img :src="require('@/assets/image/loop-rotate-mobile.gif')" class="rotate">
    <video  :src="require('@/assets/video/' + VideoSrc)" @ended="VideonEnd(true)" id="firstvideo" class="fade"></video>
  </div>

  <div v-else-if="!this.ShowReplayQuestion" class="Nomactive">
    <div v-if="this.SmartText" :class="[SmartText ? 'visible' : 'no-visible']" class="smartvideo"> <p class="fs_55 fw_800 writesmart">Con un impianto smart...</p> <p class="fs_55 fw_800 fade">{{this.SmartText2}}</p> </div>
    <video :src="require('@/assets/video/' + SmartVideo)" @ended="VideonEnd(false)" id="Secondvideo" class="fade" :class="[SmartText ? 'no-visible' : 'visible']"></video>
  </div>



  <div v-else class="crs_AnimationSlide">
    <img src="@/assets/image/logo-bticino-bianco.svg" alt="Bticino" width="150" height="50" class="logo">
    <div v-if="!this.ShowAfterAnswerModal" class="crs-wrp-flex viewQuestion fade" :id="id">
      <div class="width-50 height100 leftBlock">
        <img :src="require('@/assets/image/' + SmartImage)" alt="Bticino soluzione smart">
      </div>
      <div class="width-50 height100 rightBlock">
        <div class="blockQuestion padding-60 radius">
          <div class="relative">
            <span class="fs_80 f_fam_B id">0{{id}}</span>
            <img :src="require('@/assets/image/icon/' + 'ico-ic-0'+id+'.svg')" class="icon">
            <h3 class="question fs_28">{{QuestionSmart}}</h3>
            <div class="blk-answer smartAnswer"><div class="radius" @click="ShowHomeQuestion(); DataCollecting(Questionid, SmartIdprofile, 'si');"><p>Si</p></div></div>
            <div class="blk-answer smartAnswer"><div class="radius" @click="ShowHomeQuestion(); DataCollecting(Questionid, 'false', 'no');"><p>No</p></div></div>
          </div>
        </div>
      </div>
    </div>
    <ViewAfterAnswer v-else> </ViewAfterAnswer>
  </div>
  
</template>

<script>
import questions from '../assets/json/data.json';
import ViewAfterAnswer from './ViewAfterAnswer.vue';

export default {
  props: ['VideoSrc', 'QuestionSmart', 'id', 'SmartVideo','SmartImage', 'SmartIdprofile', 'SmartText2'],
  
  components: {
    ViewAfterAnswer,
  },

  data(){
    return{
      Questions: questions,
      VideoStart: true,
      ShowReplayQuestion: false,
      Questionid: this.$store.state.ShowIDQuestion,
      ShowAfterAnswerModal: false,
      MinUserAnswer: 3,
      Ismobile: false,
      SmartText: false
    }
  },

  mounted(){
    if(window.screen.width <= 1023) {
      this.Ismobile = true;
      this.StartVideo('firstvideo', 2000);
    }
    else {
      this.StartVideo('firstvideo', 150);
    }
  },

  methods: {

    StartVideo(data, time){
      setTimeout(function(){this.Ismobile = false;}.bind(this),1700);
      let Video = document.getElementById(data);
      let isPlaying = Video.currentTime > 0 && !Video.paused && !Video.ended && Video.readyState > Video.HAVE_CURRENT_DATA;
      if (!isPlaying) { 
        setTimeout(function(){ 
          Video.play(); 
          Video.volume = 1;
          //if(window.screen.width <= 1023) {
            Video.requestFullscreen();
          //}
        },time)}
    },

    VideonEnd: function (data) {
      if(data){ 
        this.VideoStart = false;
        this.SmartText = true;
        setTimeout(function(){ 
          this.SmartText = false;
          this.StartVideo('Secondvideo', 60);
        }.bind(this),5000);
      }
      else { this.ShowReplayQuestion = !this.ShowReplayQuestion;}
    },

    DataCollecting: function (id, value, risposta) {
      
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'Domanda2',
        'risposta': risposta,
      }); 

      let item = {id:id, value: value};
      let index = this.$store.state.DataCollecting.findIndex(x => x.id == item.id);
      if (index != -1) {
        if(value != "false") { // se ha risposto si allora sovrascrivo il dato
          this.$store.state.DataCollecting.splice(index, 1);
          this.$store.state.DataCollecting.push(item);
        }
      }
      //console.log('DataCollecting risposte ',  this.$store.state.DataCollecting);
    },


    CloseModal(){
      let Question = this.Questions.filter((question) => question.id == this.Questionid);
      Question[0].ShowAnswer = false;
    },

    /*
    * Show all question Questions (Back to Hp)
    */

    ShowHomeQuestion() {
      this.CloseModal(this.Questionid);
      if(this.$store.state.NumberActiveAnswerUser >= this.MinUserAnswer){
          this.ShowAfterAnswerModal = true;
      }
      else {
        this.$store.state.ShowFlowVideoQuestion = !this.$store.state.ShowFlowVideoQuestion;
        this.$store.state.ShowFlowQuestion = !this.$store.state.ShowFlowQuestion;
      }
      
    }
  }
}
</script>
