<template>
<div>
  <img src="@/assets/image/logo-bticino-bianco.svg" alt="Bticino" width="150" height="50" class="logo">
  <div class="crs-wrp-flex viewQuestion crs_AnimationSlide" :id="id">
    <div class="width-50 height100 leftBlock">
      <video autoplay loop :src="require('@/assets/video/' + defaultvideo)" class="fade translate"></video>
    </div>
    <div class="width-50 height100 rightBlock">
      <div class="blockQuestion padding-60 radius fade">
        <div class="relative">
          <span class="fs_80 f_fam_B id">0{{id}}</span>
          <span class="time">{{time}}</span>
          <img :src="require('@/assets/image/icon/' + 'ico-ic-0'+id+'.svg')" class="icon">
          <h3 class="question fs_28">{{question}} <br/> <span class="f_fam_B fs_36">Cosa fai?</span></h3>
          <div class="blk-answer" v-for="answer in answers" :key="answer.id">
            <div v-bind:value="answer.value" @click="activeResponse(answer.id, answer.video, Questions); track(id, answer.text); DataCollecting(id, answer.value); CheckModalAnswer(id);" :class="[{ active : active_answer == answer.id }, 'radius']">
              <p class="fs_20">{{answer.text}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>

import question from '../assets/json/data.json';

export default {
  props: ['answers', 'question', 'id', 'defaultvideo', 'time'],
  
  data(){
    return {
      Questions: question,
      active_answer: 0,
      Answersvideo: '',
      Changevideo: false,
    }
  },

  methods: {

    CheckModalAnswer(id){
      let Question = this.Questions.filter((question) => question.id == id);
      Question[0].UserReplied = true;
      this.Changevideo = true;

    },

    activeResponse(el, video){
      this.active_answer = el;
      this.Answersvideo = video;
      this.$root.$emit('ShowVideo', video);
    },

    /* 
     * Function that adds or deletes user responses in an array
     * the array is a state variable './store.js' -> DataCollecting
     * function also calculates the number of active responses, through a global counter './store.js' -> NumberActiveAnswerUser
    */

  track(id, value){
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'Domanda1',
      'risposta': value,
    }); 
  },

    DataCollecting(id, value){ 
    
    
      let item = {id:id, value: value};
      let index = this.$store.state.DataCollecting.findIndex(x => x.id == item.id);
      if (index === -1) {
        this.$store.state.DataCollecting.push(item);
        this.$store.state.NumberActiveAnswerUser++
      }
      else {
        this.$store.state.DataCollecting.splice(index, 1);
        this.$store.state.DataCollecting.push(item);
      }

      //console.log('DataCollecting prime risposte ',  this.$store.state.DataCollecting);
    },
    
  }
}
</script>