<template>
  <div class="crs-wrp-flex viewQuestion Userprofile no-m-flex crs_AnimationSlide">
    
    <div class="width-50 height100 rightBlock"></div>
    <div class="width-50 height100 leftBlock"></div>
    <div class="ViewAfterAnswer crs-wrp-flex padding-40 fade radius">
      <div class="width-40 padding-20">
        <img :src="require('@/assets/image/profili/' + this.ProfileImg)" :alt="this.ProfileName">
      </div>
      <div class="width-60 padding-20 blockrightprofile">
        <p class="fs_16 profile_text">Il tuo profilo è:</p>
        <h3 class="question fs_55 f_fam_B">{{this.ProfileName}}</h3>
        <img :src="require('@/assets/image/icon/' + 'ico-ic_profilo_finale.svg')" class="icon_profile">
        <p class="fs_20" v-html="this.ProfileDescription"> </p>
        <div class="crs-wrp-flex crs-wrp-button ">
          <ButtonGotoPreventivatore></ButtonGotoPreventivatore>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonGotoPreventivatore from './buttons/ButtonGotoPreventivatore.vue';
import profiles from '../assets/json/profile.json';

export default {
  props: {
    ShowProfileID: {
      type: Number
    }
  },
  components: {
    ButtonGotoPreventivatore,
  },

  data() {
    return {
      profile: profiles,
      ProfileName: '',
      ProfileDescription: '',
      ProfileImg: '',
    }
  },

  mounted() {
    let profile = this.profile.filter((profile) => profile.id == this.ShowProfileID);
    this.ProfileName = profile[0].ProfileName;
    this.ProfileDescription = profile[0].Profiledescription;
    this.ProfileImg = profile[0].ProfileImg;

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'Pageview',
      'pagePath': window.location.origin + '/profilo',
      'pageTitle':  this.ProfileName,
    });
  }
}
</script>