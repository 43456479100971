<template>
<div>
  <div class="crs-wrp-flex viewQuestion afterAnswer fade">
    <div class="width-50 height100 leftBlock"></div>
    <div class="width-50 height100 rightBlock"></div>
    <div class="ViewAfterAnswer padding-60 radius">
      <span class="fs_80 f_fam_B id">0{{this.$store.state.ShowIDQuestion}}</span>
      <h3 class="question fs_36">Hai risposto a {{this.$store.state.NumberActiveAnswerUser}} domande puoi continuare o andare a configurare <br/> <span>Cosa fai?</span></h3>
      <div class="crs-wrp-flex wrp-cs-button no-m-flex">
        <ButtonGotoPreventivatore></ButtonGotoPreventivatore>
        <ButtonShowProfile></ButtonShowProfile>
        <div @click="ShowHomeQuestion()" class="button"> RISPONDI ANCORA</div>
      </div>
    </div>
  </div>
</div>
</template>

<script>

import ButtonShowProfile from './buttons/ButtonShowProfile.vue';
import ButtonGotoPreventivatore from './buttons/ButtonGotoPreventivatore.vue';
import questions from '../assets/json/data.json';

export default {
  components: {
      ButtonShowProfile,
      ButtonGotoPreventivatore,
  },

  mounted(){
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'Pageview',
      'pagePath': window.location.origin + '/pre-profilo',
      'pageTitle': 'Preprofilo',
    });
  },

  data() {
    return{
      Questions: questions,
      Questionid: this.$store.state.ShowIDQuestion,
    }
  },

  methods: {
    CloseModal(){
      let Question = this.Questions.filter((question) => question.id == this.Questionid);
      Question[0].ShowAnswer = false;
    },
    ShowHomeQuestion() {

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'click_button',
        'risposta': 'Continua gioco',
      }); 

      this.CloseModal(this.Questionid);
      this.$store.state.ShowFlowVideoQuestion = !this.$store.state.ShowFlowVideoQuestion;
      this.$store.state.ShowFlowQuestion = !this.$store.state.ShowFlowQuestion;
    }
  },
}

</script>