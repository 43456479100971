<template>
  <div class="crs-cnt-qz" :class="ShowButtons ? 'showbuttons' : ''">
    <h1 class="fs_55 f_fam_B fade" v-html="title"></h1>
    <p class="fs_30 fade">{{description}}</p>
    <div v-if="ShowButtons" class="crs-wrp-button fade">
      <ButtonGotoPreventivatore></ButtonGotoPreventivatore>
      <ButtonShowProfile></ButtonShowProfile>
    </div>
  </div>
</template>

<script>
import ButtonGotoPreventivatore from './buttons/ButtonGotoPreventivatore.vue';
import ButtonShowProfile from './buttons/ButtonShowProfile.vue';

export default {
  components: {
    ButtonGotoPreventivatore,
    ButtonShowProfile
  },

  data(){
    return {
      title: '',
      description: '',
      ShowButtons: false
    }
  },

  mounted() {
    switch(this.$store.state.NumberActiveAnswerUser) {
      case 1:
        this.title = 'Risolvi gli imprevisti e scopri quanto sei smart!';
        this.description = 'La giornata va avanti: guarda ancora due scene per scoprire il tuo profilo.';
      break;
      case 2:
        this.title = 'Risolvi gli imprevisti e scopri quanto sei smart!';
        this.description = 'La giornata va avanti: guarda ancora una scena per scoprire il tuo profilo.';
      break;
      case 3:
        this.title = 'Risolvi gli imprevisti e scopri quanto sei smart!';
        this.description = 'Puoi continuare, creare la tua casa smart o scoprire il tuo profilo.';
        this.ShowButtons = true;
      break;
      case (this.$store.state.NumberActiveAnswerUser > 3):
        this.title = 'Hai già risolto tre imprevisti, cosa vuoi fare?';
        this.description = 'Puoi continuare, creare la tua casa smart o scoprire il tuo profilo.';
        this.ShowButtons = true;
      break;
      default :
        this.title = 'Risolvi gli imprevisti e scopri quanto sei smart!';
        this.description = 'Ogni giornata porta le sue sfide: guarda le scene e decidi come affrontarle.';
    }
    if(this.$store.state.NumberActiveAnswerUser > 3) {
      this.ShowButtons = true;
    }
  },
}
</script>
